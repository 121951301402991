import React, { useState, useEffect } from "react";
import "./Testimonials.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import prevIcon from "../../assets/img/icons/prev.svg";
import nextIcon from "../../assets/img/icons/next.svg";
import axios from "axios";
import { environment } from "../../envirement";
import TestimonialLoader from "../Loader/TestimonialLoader";

const Testimonials = () => {
  const imgPath = (imgName) => {
    return `${environment.apiUrl}/${imgName}`;
  };

  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(false); 
  const fetchTestimonials = async () => {
    try {
      setLoading(true); 
      const response = await axios.get(
        `${environment.apiUrl}/testimonialAdmin/GetTestimonialAdminList`
      );
      setTestimonials(response.data.testimonialList);
    } catch (error) {
      console.error("Error fetching testimonials:", error);
    }finally {
      setLoading(false);
    }
  };
 useEffect(() => {
  fetchTestimonials();
  }, []);


  const { t } = useTranslation();
  const customOptions = {
    items: 3,
    dots: true,
    dotsEach: 3,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navSpeed: 700,
    autoplay: true,
    navText: [
      `<img src=${prevIcon} alt="Previous" style={{ marginTop: "13px" }} />`,
      `<img src=${nextIcon} alt="Next" />`,
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };

  return (
    <>
      <section className="testimonialsSection">
        <div className="testimonialsContent">
          <Link to={"/testimonial"}>
            <h1>{t("reviews.title")}</h1>
          </Link>
        </div>
        {loading ? ( 
          
          <TestimonialLoader/>          
        ) : (
          // Display testimonials if loading is false
          <OwlCarousel className="owl-theme" {...customOptions}>
            {testimonials?.map((item, index) => (
              <div key={index} id="agent-testimonial">
                <img
                  src={imgPath(item.ClientPhoto)}
                  alt="client"
                  id="test-img"
                />
                <h2>{item.Name}</h2>
                <h3>{item.Company}</h3>
                <p>{item.Text}</p>
              </div>
              
            ))}
          </OwlCarousel>
        )}
      </section>
    </>
  );
};

export default Testimonials;