import React from "react";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import ProfileComponent from "../../../components/Authentification/Profile/ProfileComponent";
import { Helmet } from "react-helmet-async";

const ProfilePage = () => {
  return (
    <div>
      <Helmet>
        <meta name="keywords" content="profile, user profile, account informationMeryashome, orders, virtual staging, virtual renovation, 3d modeling interior, 3d modeling exterior, 2d color floor plan, item removal, image enhancement, photo modification, day to dusk" />
        <meta name="description" content="View and manage your orders and account information on our platform." />
        <link rel='canonical' href='https://www.meryashome.com/profile' />
      </Helmet>
      {/* <Header /> */}
      <ProfileComponent />
      <Footer />
    </div>
  );
};

export default ProfilePage;
