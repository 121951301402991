import React, { useState } from "react";
import "./Content.css";
import Virtualstaging from "../Virtual-staging/Virtualstaging";
import Modeling from "../Modeling/Modeling";
import VirtualRenovation from "../Virtual-renovation/VirtualRenovation";
import ItemRemoval from "../Item-removal/ItemRemoval";
import VirtualTour from "../Virtual-tour/VirtualTour";
import { Link, useLocation } from "react-router-dom"; // Add useLocation hook
import { useEffect } from "react";
import DayToDust from "../DayToDust/DayToDust";
import Plan2D from "../2DPlan/Plan2D";
import PhotoModification from "../photoModification/PhotoModification";

const Content = ({ navigate }) => {
  // State to keep track of the active tab
  const [active, setActive] = useState(1);

  // Get the current location
  const location = useLocation();

  useEffect(() => {
    // Extract the active tab from the URL
    const pathname = location.pathname;
    const lastIndex = pathname.lastIndexOf('/');
    const index = parseInt(pathname.substring(lastIndex + 1));
    if (!isNaN(index)) {
      setActive(index);
    }
  }, [location.pathname]);

  // Function to handle tab clicks and update the URL
  const handleTabClick = (index) => {
    setActive(index);

    // Update the URL when a menu button is clicked
    navigate(`/gallery/${getTabName(index)}/${index}`);
  };

  // Helper function to get the tab name based on index
  const getTabName = (index) => {
    switch (index) {
      case 1:
        return "virtual-staging";
      case 2:
        return "3d-modeling";
      case 3:
        return "virtual-renovation";
      case 4:
        return "item-removal";
      case 5:
        return "virtual-tour";
      case 6:
        return "day-to-dust";
      case 7:
        return "2D-Plan";
      case 8:
        return "Photo-modifcation";
      default:
        return "";
    }
  };

  // Function to render the component based on the active tab
  const renderComponent = () => {
    switch (active) {
      case 1:
        return <Virtualstaging />;
      case 2:
        return <Modeling />;
      case 3:
        return <VirtualRenovation />;
      case 4:
        return <ItemRemoval />;
      case 5:
        return <VirtualTour />;
      case 6:
        return <DayToDust/>
      case 7:
        return <Plan2D/>
      case 8:
        return <PhotoModification/>
      default:
        return null;
    }
  };

  return (
    <>
      <section className="galleryContentContainer">
      <div className="nav-tabss">
  <div
    className={active === 1 ? "nav-item-active" : "nav-item"}
    onClick={() => handleTabClick(1)}
  >
    <Link to="/gallery/virtual-staging/1" className="nav-link">Virtual staging</Link>
  </div>
  <div
    className={active === 2 ? "nav-item-active" : "nav-item"}
    onClick={() => handleTabClick(2)}
  >
    <Link to="/gallery/3d-modeling/2" className="nav-link">3D modeling</Link>
  </div>
  <div
    className={active === 3 ? "nav-item-active" : "nav-item"}
    onClick={() => handleTabClick(3)}
  >
    <Link to="/gallery/virtual-renovation/3" className="nav-link">Virtual renovation</Link>
  </div>
  <div
    className={active === 4 ? "nav-item-active" : "nav-item"}
    onClick={() => handleTabClick(4)}
  >
    <Link to="/gallery/item-removal/4" className="nav-link">Item removal</Link>
  </div>
  <div
    className={active === 5 ? "nav-item-active" : "nav-item"}
    onClick={() => handleTabClick(5)}
  >
    <Link to="/gallery/virtual-tour/5" className="nav-link">Virtual tour</Link>
  </div>
  <div
    className={active === 6 ? "nav-item-active" : "nav-item"}
    onClick={() => handleTabClick(6)}
  >
    <Link to="/gallery/day-to-dust/6" className="nav-link">Day to dusk</Link>
  </div>
  <div
    className={active === 7 ? "nav-item-active" : "nav-item"}
    onClick={() => handleTabClick(7)}
  >
    <Link to="/gallery/2D-Plan/7" className="nav-link">2D Plan</Link>
  </div>
  <div
    className={active === 8 ? "nav-item-active" : "nav-item"}
    onClick={() => handleTabClick(8)}
  >
    <Link to="/gallery/Photo-modifcation/8" className="nav-link">Photo modification</Link>
  </div>
</div>


        <div className="tab-content mt-2">{renderComponent()}</div>
      </section>
    </>
  );
};

export default Content;