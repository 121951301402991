import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import SignUpComponent from "../../../components/Authentification/SignUp/SignUpComponent";
import { Helmet } from 'react-helmet';

const SignUpPage = () => {
  const navigate = useNavigate();
  const storedCred = JSON.parse(localStorage.getItem("cred"));
  const isLoggedIn =
    storedCred && storedCred.user && storedCred.user.id !== null;

  useEffect(() => {
    // If the user is logged in, redirect them to the home page
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  return (
    <div>
      <Helmet>
        <meta name="keywords" content="sign up, register, authentication, user registration, Meryashome, virtual staging, virtual renovation, 3d modeling interior, 3d modeling exterior, 2d color floor plan, item removal, image enhancement, photo modification, day to dusk" />
        <meta name="description" content="Sign up to create an account and access exclusive features. Secure and user-friendly registration for a seamless experience." />
        <link rel='canonical' href='https://www.meryashome.com/sign-up' />

      </Helmet>
      {/* <Header /> */}
      <SignUpComponent />
      <Footer />
    </div>
  );
};
export default SignUpPage;
