import React from "react";
import { useLocation } from "react-router-dom";
import "./Gallery.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Content from "./content/Content";
import Partners from "../../components/HomeComponents/Partners/Partners";
import { PlaceOrder } from "../../components/HomeComponents/PlaceOrderComponent/PlaceOrder";
import Testimonials from "../../components/Testimonials/Testimonials";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const Gallery = ({ navigate }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get the current location from react-router
  const location = useLocation();

  // In this case, useEffect is used to check the initial URL and navigate accordingly
  useEffect(() => {
    // Check if the current URL path is "/gallery"
    if (location.pathname === "/gallery") {
      // If so, navigate to the default tab URL ("/gallery/virtual-staging/1")
      navigate("/gallery/virtual-staging/1");
        
      localStorage.setItem("serviceURL", `gallery/virtual-staging/1`);
   
    }
  }, [location.pathname, navigate]); // useEffect will re-run whenever location.pathname or navigate changes

  return (
    <div className="bg">
       <Helmet>
        <meta name="keywords" content="gallery, virtual staging,virtual staging, virtual renovation, 3d modeling interior, 3d modeling exterior, 2d color floor plan, item removal, image enhancement, photo modification, day to dusk, place order, Meryashome, testimonials, place order" />
        <meta name="description" content="Explore our gallery featuring virtual staging, testimonials, and our partners. Place an order for high-quality virtual staging services." />
        <link rel='canonical' href='https://www.meryashome.com/gallery/virtual-staging/1' />
      </Helmet>
      {/* <Header /> */}
      <Content navigate={navigate} />
      <Partners />
      <PlaceOrder />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default Gallery;
