import React from "react";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import ResetPasswordComponent from "../../../components/Authentification/ResetPassword/ResetPasswordComponent";
import { SnackbarProvider } from "notistack";
import { Helmet } from "react-helmet-async";


export default function ResetPasswordPage() {
  return (
    <div>
      <Helmet>
        <meta name="keywords" content="reset password, password recovery, authentication, secure password, Meryashome, virtual staging, virtual renovation, 3d modeling interior, 3d modeling exterior, 2d color floor plan, item removal, image enhancement, photo modification, day to dusk" />
        <meta name="description" content="Reset your password securely. Password recovery and authentication process for a secure account experience." />
        <link rel='canonical' href='https://www.meryashome.com/reset-password' />


      </Helmet>
      {/* <Header /> */}
      <SnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <ResetPasswordComponent /></SnackbarProvider>
      
      <Footer />
    </div>
  );
}
