import React from "react";
import Partners from "../../components/HomeComponents/Partners/Partners";
import Banner from "../../components/HomeComponents/Banner/Banner";
import Services from "../../components/HomeComponents/Services/Services";
import WeOffer from "../../components/HomeComponents/WeOffer/WeOffer";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import WhyUs from "../../components/HomeComponents/WhyUs/WhyUs";
import StagedListing from "../../components/HomeComponents/StagedListing/StagedListing";
import { PlaceOrder } from "../../components/HomeComponents/PlaceOrderComponent/PlaceOrder";
import Testimonials from "../../components/Testimonials/Testimonials";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";




const Home = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("serviceURL", "");

  }, []);

  return (
    <div className="bg">
     <Helmet>
  <meta name="keywords" content="virtual staging, virtual renovation, 3d modeling, photo modification, item removal, image enhancement, 2d color floor plan, real estate services" />
  <meta name="description" content="Virtual staging, renovation, 3D modeling, photo editing, item removal, image enhancement, and 2D floor plans for property owners and brokers." />
  <link rel='canonical' href='https://www.meryashome.com/' />


</Helmet>

      
      <Banner />
      <WhyUs />
      <StagedListing />
      <WeOffer />
      <PlaceOrder />
      <Services />
      <Partners />
      <PlaceOrder />
     <Testimonials />
      <Footer />
    </div>
  );
};

export default Home;
