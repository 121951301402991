import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/actions/UsersActions";
import "./LoginComponent.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";

const LoginComponent = () => {
  const { t } = useTranslation();
  const [cred, setCred] = useState({});
  const dispatch = useDispatch();
  const history = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = () => {
    dispatch(login(cred));
  };

  const { user, message } = useSelector((state) => state.loginDetails);
  useEffect(() => {
    if (user && user.user.id) {
      let servicetToVisit = localStorage.getItem("serviceURL");
      if(servicetToVisit ===""){
        history("/");
      }else{
        history(`/${servicetToVisit}`);
      }

   
      //ken fel localstorage famma url yemshilou sinn yhezou lel homepage
    }
  }, [user, history]);
  useEffect(() => {
    if (message) {
      enqueueSnackbar(message);
    }
  }, [message]);

  const handleToggleVisibility = () => {
    setShowPassword(!showPassword); 
  };

  return (
    <div className="login-container">
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
      <h3 className="title">{t(`logIn.logIn.title`)}</h3>
      {user && <h1>{user.message}</h1>}
     <form onKeyPress={(e) => {
  if (e.key === "Enter") {
    handleLogin();
  }
}}>
  <input
    className="login-input"
    type="email"
    placeholder={t(`logIn.logIn.placeholder1`)}
    onChange={(e) => setCred({ ...cred, Email: e.target.value })}
  />

  <Input
    className="login-input"
    type={showPassword ? "text" : "password"}
    placeholder={t(`logIn.logIn.placeholder2`)}
    onChange={(e) => setCred({ ...cred, Password: e.target.value })}
    endAdornment={
      <InputAdornment position="end">
        <IconButton onClick={handleToggleVisibility} onMouseDown={(event) => event.preventDefault()}>
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    }
  />
  <a className="signup-link" href="/reset-password" style={{ width: "100%", margin: "unset" }}>
    {t(`logIn.forgotten_password_btn`)}
  </a>
  <button className="submit-btn-login" type="button" onClick={handleLogin}>
    {t(`logIn.logIn.btn`)}
  </button>

  <a className="signup-link" href="/sign-up">
    <span style={{ color: "#041c32" }} >dont have an account ?</span> {t(`logIn.btn`)}
  </a>
</form>

    </div>
  );
};

export default LoginComponent;
