import React from "react";
import { useTranslation } from "react-i18next";
import vs from "../../../assets/img/services/WebB-A11-15x6.png";
import itemremoval from "../../../assets/img/services/WebB-A15itemRemoval.png";
import renovation from "../../../assets/img/services/WebB-A2-15x6.png";
import inter3D from "../../../assets/img/services/Web19-15x6.png"
import { Link } from "react-router-dom";
import "./Services.css";


const Services = () => {
  const { t } = useTranslation();
  

  return (
    <div className="servicesContainer">
      <div className="servicesContent">
        <h1>{t("home.our-services.title")}</h1>
        <div className="partOneServices" >
          <div className="partOneServicesContent">
            <h2>{t("home.our-services.bloc1.title")}</h2>
            <p>{t("home.our-services.bloc1.text")}</p>

            <Link to={"/services"}>
              <button>{t("home.our-services.btns")}</button>
            </Link>
           
          </div>
          <img src={vs} alt="virtual staging" />
        </div>
        <div className="partTwoServices" >
          <div className="partTwoServicesContent">
            <h2>{t("home.our-services.bloc2.title")}</h2>
            <p>{t("home.our-services.bloc2.text")}</p>
            <Link to={"/services"}>
              <button>{t("home.our-services.btns")}</button>
            </Link>
          </div>
          <img src={renovation} alt="virtual renovation" />
        </div>
        <div className="partOneServices" >
          <div className="partOneServicesContent">
            <h2>{t("home.our-services.bloc3.title")}</h2>
            <p>{t("home.our-services.bloc3.text")}</p>
            <Link to={"/services"}>
              <button>{t("home.our-services.btns")}</button>
            </Link>
          </div>
          <img src={inter3D} alt="3d modeling interior" />
        </div>
        <div className="go-to-services">
          <p>{t("home.our-services.text")}</p>
          <Link to={"/services"}>
            <button>{t("home.our-services.btn")}</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Services;
