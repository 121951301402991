import React, {useState} from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home.jsx";
import CommingSoon from "./components/CommingSoon/CommingSoon.jsx";
import PlaceOrderPage from "./pages/PlaceOrder/PlaceOrderPage.jsx";
import Testimonial from "./pages/Testimonial/Testimonial.jsx";
import Contact from "./pages/Contact/Contact.jsx";
import Gallery from "./pages/Gallery/Gallery.jsx";
import Panoramic from "./pages/Gallery/Panoramic/Panoramic";
import ServicesPage from "./pages/Services/ServicesPage";
import ServiceDetailsPage from "./pages/Services/ServiceDetails/ServiceDetailsPage";
import ServiceOrderPage from "./pages/PlaceOrder/ServiceOrder/ServiceOrderPage.jsx";
import ProtectedRoutes from "./components/ProtectedRoutes.jsx";
import ResetPasswordPage from "./pages/Authentification/ResetPassword/ResetPasswordPage.jsx";
import ProfilePage from "./pages/Authentification/Profile/ProfilePage.jsx";
import SignUpPage from "./pages/Authentification/SignUp/SignUpPage.jsx";
import LoginPage from "./pages/Authentification/Login/LoginPage.jsx";
import OrderStatus from "./pages/Authentification/Profile/OrderStatus/OrderStatus.jsx";
import VrTour from "./pages/Gallery/VR-Tour/VrTour";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import Testimonial_List from "./pages/AdminDashboard/testimonialsList/Testimonial_List";
import PaymentStatusPage from "./pages/PaymentStatusPage/PaymentStatusPage";
import ChangePWDPage from "./pages/Authentification/ResetPassword/ChangePWDPage.jsx";
import AdminTestimonial from "./pages/Testimonial/AdminTestimonial.jsx";
import AdminStagedListing from "./components/HomeComponents/StagedListing/AdminStagedListing.jsx";
import Header from "./components/Header/Header.jsx";
import AdminUsers from "./pages/AdminDashboard/UsersManagement/AdminUsers.jsx";
import AccountVerification from "./components/Authentification/SignUp/AccountVerification.jsx";

function App() {
  localStorage.setItem('reload',"true")
  const navigate = useNavigate();
  const storedCred = JSON.parse(localStorage.getItem("cred"));

  const [currency, setCurrency] = useState(localStorage.getItem("currency"));

 const getCurrency = (curr) => {
  setCurrency(curr);
  };
  return (
    <div className="App">
      <Header  getCurrency ={getCurrency}/>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<CommingSoon />} path="/comming-soon" />
        <Route element={<LoginPage />} path="/login" />
        <Route element={<ChangePWDPage/>} path="/resetPassword/:resetToken" />
        <Route element={<SignUpPage />} path="/sign-up" />
        <Route element={<ResetPasswordPage />} path="/reset-password" />
        <Route element={<AccountVerification/>} path="/verificationMail/:resetToken" />

        <Route
          path="/profile"
          element={
            <ProtectedRoutes storedCred={storedCred}>
              <ProfilePage />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/profile/:orderId"
          element={
            <ProtectedRoutes storedCred={storedCred}>
              <OrderStatus />
            </ProtectedRoutes>
          }
        />

        <Route element={<PlaceOrderPage currency={currency}/>} path="/placeOrder" />
        <Route
          path="/placeOrder/:serviceId"
          element={
            <ProtectedRoutes storedCred={storedCred}>
              <ServiceOrderPage currency={currency}/>
            </ProtectedRoutes>
          }
        />
        <Route
          path="/UsersManagement"
          element={
            <ProtectedRoutes storedCred={storedCred}>
              <AdminUsers />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/TestimonialManagement"
          element={
            <ProtectedRoutes storedCred={storedCred}>
              <AdminTestimonial />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/RecentlyStagedManagement"
          element={
            <ProtectedRoutes storedCred={storedCred}>
              <AdminStagedListing />
            </ProtectedRoutes>
          }
        />
        <Route element={<Testimonial_List />} path="/Testimonial_List" />
        <Route element={<Testimonial />} path="/testimonial" />
        <Route element={<Contact />} path="/contact" />
        <Route path="/gallery/*" element={<Gallery navigate={navigate} />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/service/:serviceId" element={<ServiceDetailsPage />} />
        {/* <Route path="/" element={<Home />} /> */}
        <Route element={<Panoramic />} path="/panoramic/:imageId" />
        <Route element={<VrTour />} path="/vrTour" />
        <Route path="/payment_status" element={<PaymentStatusPage />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/CommingSoon" element={<CommingSoon />} />
      </Routes>
    </div>
  );
}

export default App;
