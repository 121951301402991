import React from "react";
import Services from "../../../../data/services.json";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useParams, useNavigate } from "react-router-dom";
import "./ServiceDetails.css";
import { useTranslation } from "react-i18next";
import prevIcon from "../../../../assets/img/icons/prev.svg";
import nextIcon from "../../../../assets/img/icons/next.svg";
import CheckIcon from "../../../../assets/img/icons/check.svg";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const ServiceDetails = () => {
  const navigate = useNavigate();

  let { serviceId } = useParams();
  const foundService = Services.find((s) => s.name === serviceId);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (foundService) {
      localStorage.setItem("serviceURL", `placeOrder/${foundService.id}`);
    }else{
      localStorage.setItem("serviceURL", "");
    }
  }, [serviceId]);

  //fuction that gets the image name and return its full path
  const imgURL = (imgName) => {
    return require(`../../../../assets/img/${imgName}`);
  };
  const { t } = useTranslation();
  const options = {
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplay: false,
    nav: true,
    loop: false,
    navText: [
      `<img src=${prevIcon} alt="Previous" style={{ marginTop: "13px" }} />`,
      `<img src=${nextIcon} alt="Next" />`,
    ],
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      1280: {
        items: 1,
        nav: true,
      },
    },
  };

  return (
    <div className="bf-container">
       <Helmet>
        <meta name="keywords" content="virtual staging, virtual renovation, 3d modeling interior, 3d modeling exterior, 2d color floor plan, item removal, image enhancement, photo modification, day to dusk, place order, Meryashome, service details, our services, partners, testimonials, place order" />
        <meta name="description" content="Explore the details of our services. Learn about our partners and read testimonials. Place your order for a seamless experience." />
        <link rel='canonical' href={`https://www.meryashome.com/service/${serviceId}`} />
      </Helmet>
      {
        foundService && (
          <>
            <div className="bf-container-header">
              <p>
                “Our mission is to assure our client's satisfaction. We
                understand your needs and we deliver the best”
              </p>
              <p>
                {" "}
                -Yassine Mersni <span style={{fontSize: "15px"}}>(CEO & Founder of Meryas Home) </span>
              </p>
              <h1>{t(`services-page.bloc${foundService.id}.title`)}</h1>
            </div>

            <div className="bf-secret">
              <span>
                <img src={CheckIcon} alt="checked point 1" />
                <h3>
                  {t(
                    `services-page.bloc${foundService.id}.checkpoints.checkpoint-1`
                  )}
                </h3>
              </span>
              <span>
                <img src={CheckIcon} alt="checked point 2" />
                <h3>
                  {t(
                    `services-page.bloc${foundService.id}.checkpoints.checkpoint-2`
                  )}
                </h3>
              </span>
              <span>
                <img src={CheckIcon} alt="checked point 3" />
                <h3>
                  {t(
                    `services-page.bloc${foundService.id}.checkpoints.checkpoint-3`
                  )}
                </h3>
              </span>
              <span>
                <img src={CheckIcon} alt="checked point 4" />
                <h3>
                  {t(
                    `services-page.bloc${foundService.id}.checkpoints.checkpoint-4`
                  )}
                </h3>
              </span>
            </div>
            <OwlCarousel className="owl-theme-container" {...options}>
              {foundService.images.map((image, index) => (
                <div className="img-container" key={index}>
                  <img
                    style={{ width: "620px", margin: "20px 0" }}
                    src={imgURL(image.src)}
                    alt={`${image.alt}`}
                  />
                </div>
              ))}
            </OwlCarousel>
            <a
              href={`/placeOrder/${foundService.id}`}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "13px",
              }}
            >
              <button
                style={{
                  padding: "9px 30px",
                  background: "#ECB365",
                  color: "black",
                }}
              >

                {t("header.menu.m9")}
              </button>
            </a>
          </>
        )
      }
    </div>
  );
};

export default ServiceDetails;