// import React from "react";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import "./StagedListing.css";
// import listings from "../../../data/listings.json";
// import { useTranslation } from "react-i18next";
// import prevIcon from "../../../assets/img/icons/prev.svg";
// import nextIcon from "../../../assets/img/icons/next.svg";
// import WindowWidthComponent from "../../WindowWidthComponent";

// const StagedListing = () => {
//   const { t } = useTranslation();
//   const windowWidth = WindowWidthComponent();
//   const options = {
//     loop:true,
//     mouseDrag: true,
//     touchDrag: true,
//     pullDrag: true,
//     dots: true,
//     navSpeed: 700,
//     autoplay: true,
//     nav: true,
//     navText: [
//       `<img src=${prevIcon} alt="Previous" style={{ marginTop: "13px" }} />`,
//       `<img src=${nextIcon} alt="Next" />`,
//     ],
//     responsive: {
//       0: {
//         items: 1,
//         nav: false,
//       },
//       600: {
//         items: 1,
//         nav: true,
//       },
//     },
//   };
//   const listing = (imgName) => {
//     return require(`../../../assets/img/listing/${imgName}`);
//   };
//   const testimonials = (imgName) => {
//     return require(`../../../assets/img/testimonials/${imgName}`);
//   };
//   const company = (imgName) => {
//     return require(`../../../assets/img/testimonials/${imgName}`);
//   };

//   return (
//     <div className="staged-listing-container">
//       <div className="vs-title">{t("home.recent-listings.title")}</div>
//       <OwlCarousel className="owl-theme" {...options}>
//         {listings.map((item) => (
//           <div key={item.id} className="item-container">
//             <div
//               key={item.id}
//               className="item"
//               style={{
//                 justifyContent:
//                   windowWidth <= 1280 ? "center" : "space-between",
//               }}
//             >
//               {" "}
//               {windowWidth <= 1280 ? (
//                 <img
//                   id="first-image-item"
//                   key={`${item.id}-${item.images[0]}`}
//                   src={listing(item.images[0])}
//                   alt={`${item.id}`}
//                 />
//               ) : (
//                 item.images.map((image, index) => (
//                   <img
//                     id="image-item"
//                     key={index}
//                     src={listing(image)}
//                     alt={`${index}`}
//                   />
//                 ))
//               )}
//             </div>
//           </div>
//         ))}
//       </OwlCarousel>
//     </div>
//   );
// };

// export default StagedListing;

import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./StagedListing.css";
import { useTranslation } from "react-i18next";
import prevIcon from "../../../assets/img/icons/prev.svg";
import nextIcon from "../../../assets/img/icons/next.svg";
import WindowWidthComponent from "../../WindowWidthComponent";
import axios from "axios"; 
import { environment} from "../../../envirement";
import ImagesGridLoader from "../../Loader/ImagesGridLoader";
const StagedListing = () => {
  const { t } = useTranslation();
  const windowWidth = WindowWidthComponent();
  const [listings, setListings] = useState([]); 
  const [loading, setLoading] = useState(false); 
  const imgPath = (imgName) => {
    return `${environment.apiUrl}/${imgName}`;
  };
  useEffect(() => {
    const fetchListings = async () => {
      try {
        setLoading(true); 
        const response = await axios.get(
          `${environment.apiUrl}/recentlyStaged/getAllRecentlyStaged`
        );
        setListings(response.data); 
      } catch (error) {
        console.error("Error fetching listings:", error);
      }finally {
        setLoading(false);
      }
    };

    fetchListings(); 
  }, []);

  
  const options = {
    loop:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    autoplay: true,
    nav: true,
    navText: [
      `<img src=${prevIcon} alt="Previous" style={{ marginTop: "13px" }} />`,
      `<img src=${nextIcon} alt="Next" />`,
    ],
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      600: {
        items: 1,
        nav: true,
      },
    },
  };
  return (
    <div className="staged-listing-container">
      <div className="vs-title">{t("home.recent-listings.title")}</div>
      {loading ? (<ImagesGridLoader/>):(<OwlCarousel className="owl-theme" {...options}>
        {listings?.map((item) => (
          <div key={item.id} className="item-container">
            <div
              key={item.id}
              className="item"
              style={{
                justifyContent:
                  windowWidth <= 1280 ? "center" : "space-between",
              }}
            >
              {windowWidth <= 1280 ? (
                <img
                  id="first-image-item"
                  key={`${item.id}`}
                  src={imgPath(item.Images[0])}
                  alt={`${item.id}`}
                />
              ) : (
                item?.Images?.map((image, index) => (
                  <img
                    id="image-item"
                    key={index}
                    src={imgPath(image)}
                    alt={`${index}`}
                  />
                ))
              )}
            </div>
          </div>
        ))}
      </OwlCarousel>)}
      
    </div>
  );
};

export default StagedListing;