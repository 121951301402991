import React from "react";
import { useTranslation } from "react-i18next";
import Untitled1 from "../../assets/img/services/Untitled-1 1.png";
import Untitled2 from "../../assets/img/services/Untitled-2 4.png";
import Untitled3 from "../../assets/img/services/Untitled-2 5.png";
import { Link, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import Partners from "../../components/HomeComponents/Partners/Partners";
import { PlaceOrder } from "../../components/HomeComponents/PlaceOrderComponent/PlaceOrder";
import Testimonials from "../../components/Testimonials/Testimonials";
import Footer from "../../components/Footer/Footer";
import { useRef } from "react";
import { useEffect } from "react";
import itemremoval from "../../assets/img/services/WebB-A15-15x6.png";
import renovation from "../../assets/img/services/WebB-A2-15x6.png";
import inter3D from "../../assets/img/services/Web19-15x6.png"
import exter3D from "../../assets/img/3dModeling/Meryas-Façade 2.jpg"
import plan2D from "../../assets/img/services/WebB-A17-15x6.png"
import D2D from "../../assets/img/services/WebB-A1-15x6.png"
import enhancementimg from "../../assets/img/services/WebB-A18-15x6.png"
import photomodif from "../../assets/img/services/WebB-A14-15x6.png"
import vs from "../../assets/img/services/WebB-A11-15x6.png"
import { Helmet } from "react-helmet-async";
const ServicesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  const location = useLocation();

  const stagingRef = useRef(null);
  const renovationRef = useRef(null);
  const modelingRef = useRef(null);
  const enhancementRef = useRef(null);
  const removalRef = useRef(null);
  const twoDRef = useRef(null);
  const modelingExtRef = useRef(null);
  const ddRef = useRef(null);
  const lccRef = useRef(null);

  useEffect(() => {
    const scrollElementId = location.hash.substring(1);

    const element =
      scrollElementId === "STAGING"
        ? stagingRef.current
        : scrollElementId === "RENOVATION"
        ? renovationRef.current
        : scrollElementId === "MODELINGINT"
        ? modelingRef.current
        : scrollElementId === "MODELINGEXT"
        ? modelingExtRef.current
        : scrollElementId === "ENHANCEMENT"
        ? enhancementRef.current
        : scrollElementId === "REMOVAL"
        ? removalRef.current
        : scrollElementId === "PM"
        ? lccRef.current
        : scrollElementId === "DD"
        ? ddRef.current
        : scrollElementId === "2D"
        ? twoDRef.current
        : null;

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
  useEffect(()=>{
    localStorage.setItem("serviceURL", `services`);
  },[])
  return (
    <>
    <Helmet>
        <meta name="keywords" content="virtual staging, virtual renovation, 3d modeling, image enhancement, item removal, 2d color floor plan, day to dusk, photo modification" />
        <meta name="description" content="Explore our services including virtual staging, virtual renovation, 3D modeling, image enhancement, item removal, 2D color floor plan, day to dusk, and photo modification. Elevate your property with our expert services." />
        <link rel='canonical' href='https://www.meryashome.com/services' />


      </Helmet>
      {/* <Header /> */}
      <div className="servicesContainer">
        <div className="servicesContent">
          <h1>{t("services-page.title")}</h1>
          <div className="partOneServices" id="STAGING" ref={stagingRef}>
            <div className="partOneServicesContent">
              <h2>{t("services-page.bloc1.title")}</h2>
              <p>{t("services-page.bloc1.text")}</p>
              <Link to={"/service/virtual-staging"}>
                <button>{t("services-page.btn")}</button>
              </Link>
            </div>
            <img src={vs} alt="virtual staging service" />
            <div ref={renovationRef} />

          </div >
          <div id="RENOVATION" className="partTwoServices" >
            <div className="partTwoServicesContent">
              <h2>{t("services-page.bloc2.title")}</h2>
              <p>{t("services-page.bloc2.text")}</p>
              <Link to={"/service/virtual-renovation"}>
                <button>{t("services-page.btn")}</button>
              </Link>
            </div>
            <img src={renovation} alt="virtual renovation service" />
            <div ref={modelingRef} />
          </div>
          <div id="MODELINGINT" className="partOneServices" >
            <div className="partOneServicesContent">
              <h2>{t("services-page.bloc3.title")}</h2>
              <p>{t("services-page.bloc3.text")}</p>
              <Link to={"/service/3d-modeling(interior)"}>
                <button>{t("services-page.btn")}</button>{" "}
              </Link>
            </div>
            <img src={inter3D} alt="3d modeling interior service" />
            <div ref={modelingExtRef} />
          </div>
          <div
            id="MODELINGEXT"
            className="partTwoServices"
          >
            <div className="partTwoServicesContent">
              <h2>{t("services-page.bloc4.title")}</h2>
              <p>{t("services-page.bloc4.text")}</p>
              <Link to={"/service/3d-modeling(exterior)"}>
                <button>{t("services-page.btn")}</button>{" "}
              </Link>
            </div>
            <img src={exter3D} alt="3d modeling exterior service" />
            <div ref={enhancementRef}></div>

          </div>
          <div
            id="ENHANCEMENT"
            className="partOneServices"
          >
            <div className="partOneServicesContent">
              <h2>{t("services-page.bloc5.title")}</h2>
              <p>{t("services-page.bloc5.text")}</p>
              <Link to={"/service/image-inhancement"}>
                <button>{t("services-page.btn")}</button>{" "}
              </Link>
            </div>
            <img src={enhancementimg} alt="image enhancement service" />
            <div ref={removalRef}></div>

          </div>
          <div id="REMOVAL" className="partTwoServices" >
            <div className="partTwoServicesContent">
              <h2>{t("services-page.bloc6.title")}</h2>
              <p>{t("services-page.bloc6.text")}</p>
              <Link to={"/service/itme-removal"}>
                <button>{t("services-page.btn")}</button>{" "}
              </Link>
            </div>
            <img src={itemremoval} alt="item removal service" />
            <div ref={twoDRef}></div>

          </div>
          <div id="2D" className="partOneServices">
            <div className="partOneServicesContent">
              <h2>{t("services-page.bloc8.title")}</h2>
              <p>{t("services-page.bloc8.text")}</p>
              <Link to={"/service/2d-color-floor-plan"}>
                <button>{t("services-page.btn")}</button>
              </Link>
            </div>
            <img src={plan2D} alt="2d color floor plan service" />
            <div ref={ddRef}></div>

          </div>
          <div id="DD" className="partTwoServices" >
            <div className="partTwoServicesContent">
              <h2>{t("services-page.bloc7.title")}</h2>
              <p>{t("services-page.bloc7.text")}</p>
              <Link to={"/service/day-to-dusk"}>
                <button>{t("services-page.btn")}</button>{" "}
              </Link>
            </div>
            <img src={D2D} alt="day to dusk" />
            <div ref={lccRef}></div>

          </div>
          
          <div id="PM" className="partOneServices" >
            <div className="partOneServicesContent">
              <h2>{t("services-page.bloc9.title")}</h2>
              <p>{t("services-page.bloc9.text")}</p>
              <Link to={"/service/photo-modification"}>
                <button>{t("services-page.btn")}</button>
              </Link>
            </div>
            <img src={photomodif} alt="photo modification service" />
          </div>
        </div>
      </div>
      <Partners />
      <PlaceOrder />
      <Testimonials />
      <Footer />
    </>
  );
};

export default ServicesPage;
