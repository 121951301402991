import React, { useState, useEffect } from "react";
import "./header.css";
import {
  VscMenu,
  VscMail,
  VscChromeClose,
  VscChevronDown,
} from "react-icons/vsc";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import svgImage from "../../assets/img/icons/iphone.svg";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/UsersActions";
import { environment } from "../../envirement";
import WindowWidthComponent from "../WindowWidthComponent";
import USD from "../../assets/img/flags/us.png";
import CurrencyFlagComponent from "../FlagComponent";
const Header = ({ getCurrency }) => {
  const windowWidth = WindowWidthComponent();

  const dispatch = useDispatch();

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const credString = localStorage.getItem("cred");
    const cred = JSON.parse(credString);
    setIsAdmin(cred && cred.user && cred.user.Role === "Superadmin");
  });

  const handleLogout = () => {
    dispatch(logout());
    localStorage.setItem("serviceURL", "");
    setIsAdmin(false);
  };

  const storedCred = JSON.parse(localStorage.getItem("cred"));
  const isLoggedIn =
    storedCred && storedCred.user && storedCred.user.id !== null;

  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(
    localStorage.getItem("lng") || "en"
  );
  const [activeCurrency, setActiveCurrency] = useState(
    localStorage.getItem("currency") || "CAD"
  );

  const [flagCurrency, setFlagCurrency] = useState(null);

  useEffect(() => {
    // Vérifie si la devise est déjà stockée dans le localStorage
    const storedCurrency = localStorage.getItem("currency");

    // Si la devise n'est pas déjà stockée, définissez-la sur CAD par défaut
    if (!storedCurrency) {
      localStorage.setItem("currency", "CAD");
    }
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
    localStorage.setItem("lng", lng);
    // console.log(`Language changed to: ${lng}`);
  };
  const changeCurrency = (curr) => {
    if (curr === "OTHER") {
      setActiveCurrency("OTHER"); // Modifier ici pour afficher "Other countries" dans le menu déroulant
      localStorage.setItem("currency", "USD");
      getCurrency("USD");
      setFlagCurrency(USD);
    } else {
      setActiveCurrency(curr);
      localStorage.setItem("currency", curr);
      getCurrency(curr);
      setFlagCurrency(curr);
    }
    toggleAccordion();
  };

  const toggleSubMenu = () => {
    // Implementation for toggling the sub-menu goes here
  };
  const closeNavbar = () => {
    const checkbox = document.getElementById("check");
    if (checkbox.checked) {
      checkbox.checked = false;
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "black",
          margin: 0,
          position: "sticky",
          top: 0,
          zIndex: 3,
        }}
      >
        <div className="all">
          <div className="headerTop">
            <label htmlFor="check" className="checkbtn">
              <VscMenu id="menuicon" />
            </label>
            <div className="black-header">
              <div className="phoneNumber">
                <img src={svgImage} alt="SVG" />
                <p>1 888-649-8993</p>
              </div>
              <div className="phoneNumber">
                <VscMail style={{ fontSize: "20px" }} />
                <p>info@meryashome.com</p>
              </div>
              <p>{t("header.topPart.text")}</p>

              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <div className="currency-flag-container">
                  <CurrencyFlagComponent currency={activeCurrency} />
                </div>

                <select
                  className="currency-select"
                  style={{
                    width: "144px",
                    height: "28px",
                    fontFamily: "Nunito, sans-serif",
                    fontSize: "12px",
                    padding: "3px",
                  }}
                  onChange={(e) => changeCurrency(e.target.value)}
                  value={activeCurrency}
                >
                  <option value="USD">USD $</option>
                  <option value="EUR">EUR €</option>
                  <option value="AUD">AUD $</option>
                  <option value="CAD">CAD $</option>
                  <option value="GBP">GBP £</option>
                  <option value="NZD">NZD $</option>
                  <option value="OTHER">Other countries</option>
                </select>
              </div>
              <p>
                <span
                  className={`language ${
                    activeLanguage === "en" ? "active" : ""
                  }`}
                  onClick={() => changeLanguage("en")}
                >
                  ENGLISH
                </span>
                -
                <span
                  className={`language ${
                    activeLanguage === "fr" ? "active" : ""
                  }`}
                  onClick={() => changeLanguage("fr")}
                >
                  français
                </span>
              </p>
            </div>
          </div>

          <div className="BG">
            <nav>
              <input type="checkbox" id="check" />
              {windowWidth <= 1280 && (
                <label htmlFor="check" className="checkbtn">
                  <VscMenu id="menuicon" />
                </label>
              )}
              <Link to={"/"} className="a">
                <label className="logo">
                  <img
                    src={require("../../assets/img/lionLogo.png")}
                    alt="meryas icon"
                  />
                </label>
              </Link>
              <ul id="nav_ul">
                <label
                  htmlFor="check"
                  className="checkbtn"
                  // onClick={closeNavbar} // Call the function to close navbar on click
                >
                  <div id="closeNav">
                    <VscChromeClose />
                  </div>
                </label>
                {windowWidth <= 1020 && (
                  <p>
                    <span
                      className={`language ${
                        activeLanguage === "en" ? "active" : ""
                      }`}
                      onClick={() => changeLanguage("en")}
                      style={{ cursor: "pointer" }}
                    >
                      ENGLISH
                    </span>
                    -
                    <span
                      className={`language ${
                        activeLanguage === "fr" ? "active" : ""
                      }`}
                      onClick={() => changeLanguage("fr")}
                      style={{ cursor: "pointer" }}
                    >
                      français
                    </span>
                  </p>
                )}
                <li className="link">
                  {isAdmin ? (<Link to={"/usersManagement"} className="a" onClick={closeNavbar}>
                  Users Management
                  </Link>):(<Link to={"/"} className="a" onClick={closeNavbar}>
                    {t("header.menu.m2")}
                  </Link>)}
                </li>
                {isAdmin ? (
                  <li>
                    <Link
                      to={"/testimonialManagement"}
                      className="a"
                      onClick={closeNavbar}
                    >
                      Testimonial Management
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link
                      to={"/service/virtual-staging"}
                      className="a"
                      onClick={closeNavbar}
                    >
                      {t("header.menu.m3")}
                    </Link>
                  </li>
                )}
                {isAdmin ? (
                  <li>
                    <Link
                      to={"/recentlyStagedManagement"}
                      className="a"
                      onClick={closeNavbar}
                    >
                      Staged listing Management
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link
                      to={"/service/3d-modeling(interior)"}
                      className="a"
                      onClick={closeNavbar}
                    >
                      {t("header.menu.m4")}
                    </Link>
                  </li>
                )}
                <li id="dropdown">
                  <Link
                    to={"/services"}
                    className="a"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={toggleSubMenu}
                  >
                    <span>{t("header.menu.m6")}</span>
                    <VscChevronDown id="icon" />
                  </Link>
                  <ul
                    id="service-sub-menu"
                    style={{
                      transition: "0.1s ease-in-out",
                      width: "fit-content",
                    }}
                  >
                    <li>
                      <Link
                        to={`${environment.FrontUrl}/service/virtual-staging`}
                        className="a"
                        onClick={closeNavbar}
                      >
                        {t("header.nav-bar-services.s1")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${environment.FrontUrl}/service/virtual-renovation`}
                        className="a"
                        onClick={closeNavbar}
                      >
                        {t("header.nav-bar-services.s2")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${environment.FrontUrl}/service/3d-modeling(interior)`}
                        className="a"
                        onClick={closeNavbar}
                      >
                        {t("header.nav-bar-services.s3")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${environment.FrontUrl}/service/3d-modeling(exterior)`}
                        className="a"
                        onClick={closeNavbar}
                      >
                        {t("header.nav-bar-services.s4")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${environment.FrontUrl}/service/image-inhancement`}
                        className="a"
                        onClick={closeNavbar}
                      >
                        {t("header.nav-bar-services.s5")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${environment.FrontUrl}/service/itme-removal`}
                        className="a"
                        onClick={closeNavbar}
                      >
                        {t("header.nav-bar-services.s6")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${environment.FrontUrl}/service/2d-color-floor-plan`}
                        className="a"
                        onClick={closeNavbar}
                      >
                        {t("header.nav-bar-services.s8")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${environment.FrontUrl}/service/day-to-dusk`}
                        className="a"
                        onClick={closeNavbar}
                      >
                        {t("header.nav-bar-services.s7")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`${environment.FrontUrl}/service/photo-modification`}
                        className="a"
                        onClick={closeNavbar}
                      >
                        {t("header.nav-bar-services.s9")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to={"/gallery"} className="a" onClick={closeNavbar}>
                    {t("header.menu.m7")}
                  </Link>
                </li>
                {!isAdmin && (
                  <li>
                    <Link to={"/contact"} className="a" onClick={closeNavbar}>
                      {t("header.menu.m8")}
                    </Link>
                  </li>
                )}
                <div style={{ display: "flex" }}>
                  {windowWidth <= 1020 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "stretch",
                        width: "min-content",
                      }}
                    >
                      <div className="currency-flag-container">
                        <CurrencyFlagComponent currency={activeCurrency} />
                      </div>
                      <div className="currency-accordion">
                        <button
                          className="currency-toggle"
                          onClick={toggleAccordion}
                        >
                          {activeCurrency}
                        </button>
                        <div
                          className={`currency-options ${isOpen ? "open" : ""}`}
                        >
                          {/* Button for each currency */}
                          <button
                            className={`currency-tab ${
                              activeCurrency === "USD" ? "active" : ""
                            }`}
                            onClick={() => changeCurrency("USD")}
                          >
                            USD $
                          </button>
                          <button
                            className={`currency-tab ${
                              activeCurrency === "EUR" ? "active" : ""
                            }`}
                            onClick={() => changeCurrency("EUR")}
                          >
                            EUR €
                          </button>
                          <button
                            className={`currency-tab ${
                              activeCurrency === "AUD" ? "active" : ""
                            }`}
                            onClick={() => changeCurrency("AUD")}
                          >
                            AUD $
                          </button>
                          <button
                            className={`currency-tab ${
                              activeCurrency === "CAD" ? "active" : ""
                            }`}
                            onClick={() => changeCurrency("CAD")}
                          >
                            CAD $
                          </button>
                          <button
                            className={`currency-tab ${
                              activeCurrency === "GBP" ? "active" : ""
                            }`}
                            onClick={() => changeCurrency("GBP")}
                          >
                            GBP £
                          </button>
                          <button
                            className={`currency-tab ${
                              activeCurrency === "NZD" ? "active" : ""
                            }`}
                            onClick={() => changeCurrency("NZD")}
                          >
                            NZD $
                          </button>
                          <button
                            className={`currency-tab ${
                              activeCurrency === "OTHER" ? "active" : ""
                            }`}
                            onClick={() => changeCurrency("OTHER")}
                          >
                            Other countries
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="btns">
                  {isLoggedIn ? (
                    <>
                      {" "}
                      <li>
                        <Link
                          onClick={handleLogout}
                          to={"/"}
                          className="a nav-link-btn"
                          id="login"
                          
                        >
                          {t("header.topPart.logout")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/profile"}
                          id="order"
                          onClick={closeNavbar}
                          className="a nav-link-btn"
                        >
                          {t("header.topPart.orders")}
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link
                          to={"/login"}
                          className="a nav-link-btn"
                          id="login"
                          onClick={closeNavbar}
                        >
                          {t("header.topPart.Login")}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/placeOrder"}
                          onClick={closeNavbar}
                          id="order"
                          className="a nav-link-btn"
                        >
                          {t("header.menu.m9")}
                        </Link>
                      </li>
                    </>
                  )}
                </div>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
