import React, { useState } from "react";
import "./OrderCard.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { environment } from "../../../envirement";
const OrderCard = ({ displayMode, orders, isAdmin }) => {
  const [hoveredArticle, setHoveredArticle] = useState(null);

  const handleMouseOver = (articleId) => {
    setHoveredArticle(articleId);
  };

  const handleMouseOut = () => {
    setHoveredArticle(null);
  };

  const { t } = useTranslation();
  const imgPath = (imgName) => {
    return `${environment.apiUrl}/${imgName}`;
  };

  const addressDisplay = (address) => {
    if (address && address.length > 38) {
      const truncatedAddress = address.substring(0, 38) + "...";
      return <>{truncatedAddress}</>;
    } else if (address) {
      return <>{address}</>;
    } else {
      return "N/A";
    }
  };

  let Orders = orders.orders;
  // console.log(Orders.map((order)=>{

  //  return order
  // }))

  // console.log(Orders);

  return (
    <div className={`articles-container ${displayMode}`}>
      <section className="articles">
        {Orders.map((order) => (
          <article
            key={order.id}
            className={order.id === hoveredArticle && "bigger-article"}
            onMouseOver={() => handleMouseOver(order.id)}
            onMouseOut={handleMouseOut}
          >
            {displayMode === "tableau" ? (
              <div className="article-wrapper">
                <figure>
                  {order && order.Images[0] && order.Images[0].Image && (
                    <img src={imgPath(order.Images[0].Image)} alt={""} />
                  )}
                </figure>
                <div className="article-body">
                  <h2>{order.Ref}</h2>
                  <p>
                    {t("account.address-label")} :{" "}
                    {order.id === hoveredArticle
                      ? order.Address
                        ? order.Address
                        : "N/A"
                      : addressDisplay(order.Address)}
                  </p>
                </div>
                {isAdmin ? (
                  <div
                    style={{
                      padding: "0 10px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "Nunito",
                        lineHeight: "24px",
                        letterSpacing: "0.02em",
                        textAlign: "center",
                        color: "black",
                        fontSize: "14px",
                      }}
                    >
                      {`${order.User_First_Name} ${order.User_Last_Name}`}
                    </span>
                    <Link
                      to={`/profile/${order.id}`}
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "800",
                        lineHeight: "24px",
                        letterSpacing: "0.02em",
                        textAlign: "center",
                        color: "#006FB7",
                        textDecoration: "underline",
                        fontSize: "14px",
                      }}
                    >
                      {t("account.btn")}
                    </Link>
                  </div>
                ) : (
                  <Link to={`/profile/${order.id}`} className="read-more">
                    {t("account.btn")}
                  </Link>
                )}
              </div>
            ) : (
              <div className="article-wrapper-list">
                {/* <div className="circular-image-container">
                  <img
                    src={imgPath(order.Images[0].Image)}
                    alt={""}
                    className="circular-image"
                  />
                </div> */}
                <div className="article-body-list">
                  <h2>{order.Ref} </h2>
                  <p>
                    {t("account.address-label")} :{" "}
                    {order.id === hoveredArticle
                      ? order.Address
                        ? order.Address
                        : "N/A"
                      : addressDisplay(order.Address)}
                  </p>
                </div>
                {isAdmin ? (
                  <>
                    <p
                      style={{
                        fontFamily: "Nunito",
                        lineHeight: "24px",
                        letterSpacing: "0.02em",
                        textAlign: "center",
                        color: "black",
                        fontSize: "14px",
                      }}
                    >{`${order.User_First_Name}_${order.User_Last_Name}`}</p>
                    <Link to={`/profile/${order.id}`} className="read-more">
                      {t("account.btn")}
                    </Link>
                  </>
                ) : (
                  <Link to={`/profile/${order.id}`} className="read-more">
                    {t("account.btn")}
                  </Link>
                )}
              </div>
            )}
          </article>
        ))}
      </section>
    </div>
  );
};

export default OrderCard;
