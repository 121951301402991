import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Credit.css";
import prices from "../../data/totalPrice.json";
import servicesprices from "../../data/servicesPrices.json";

const Credit = ({ uploadedImgs, orderInfos, getCredits, getPrice, title, currency }) => {

// console.log("orderInfos")
// console.log(orderInfos)
  const getCurrencySymbol = (currencyCode) => {
    switch (currencyCode) {
      case 'USD':
        return '$USD';
      case 'EUR':
        return '€';
      case 'AUD':
  return '$AUD';
      case 'CAD':
        return '$CAD';
      case 'GBP':
        return '£';
      case 'NZD':
        return '$NZD';
      default:
        return ''; // Si la devise n'est pas trouvée, retournez une chaîne vide
    }
  };
  
  
  
  const { t } = useTranslation();

  let credString = localStorage.getItem("cred");
  let cred = JSON.parse(credString);
  let restCredits = cred.user.Credit;
  let totals = 0;
  let addedImgs = 0;
  let totalCredits = 0;



  const isVirtualStaging =  title === "virtual staging" || title === "mise en scène virtuelle";
  const isVirtualRenovation =  title === "virtual renovation" || title === "rénovation virtuelle";
  const isThreeDInterior =  title === "3d modeling (interior)" || title === "Modélisation 3D (intérieur)";
  const isThreeDExterior = title === "3d modeling (exterior)" || title === "Modélisation 3D (extérieur)"; 
  const isItemRemoval = title === "Item removal" || title === "Suppression d'objet";  
  const isImgEnh = title === "Image enhancement" || title === "Amélioration de l'image";  
  const isTwoD = title === "2D color floor plan" || title === "Plan d'architecture en couleur"; 
  const isDayToDusk = title === "Day to Dusk" || title === "Crépuscule"; 
  const isLuminosity = title === "Photo modification" || title === "Modification de photo"; 
// console.log("----------------------------------")
// console.log(prices)
// console.log("----------------------------------")

  //case virtual staging : 
  if(isVirtualStaging){
    //case 1
    if (orderInfos.checked === false && cred.user.Credit === 0) {
      // console.log("case 1 detected - pack = non , credit = 0");
      restCredits = 0;
      if (uploadedImgs) {
        if (uploadedImgs.length <= 6) {
          totals = uploadedImgs.length * prices?.['35']?.[currency];
        } else if (uploadedImgs.length >= 7 && uploadedImgs.length <= 11) {
          totals = uploadedImgs.length * prices?.['30']?.[currency];
        } else if (uploadedImgs.length >= 12 && uploadedImgs.length <= 19) {
          totals = uploadedImgs.length * prices?.['25']?.[currency];
        } else if (uploadedImgs.length >= 20 && uploadedImgs.length <= 49) {
          totals = uploadedImgs.length * prices?.['22.5']?.[currency];
        } else if (uploadedImgs.length >= 50) {
          totals = uploadedImgs.length * prices?.['20']?.[currency];
        }
        getCredits(0);
        getPrice(totals);
        // console.log("totals");
        // console.log(totals);
        // console.log("credit");
        // console.log(restCredits);
      }
    }
    //case 2
    if (orderInfos.checked === true && cred.user.Credit === 0) {
      // console.log("case 2 detected - pack = oui , credit = 0");
      totals = orderInfos.price;
      restCredits = orderInfos.credit;
      if (uploadedImgs) {
        if (orderInfos.credit - uploadedImgs.length < 0) {
          restCredits = 0;
          if (uploadedImgs.length <= 6) {
            totals = uploadedImgs.length * prices?.['35']?.[currency];
          } else if (uploadedImgs.length >= 7 && uploadedImgs.length <= 11) {
            totals = uploadedImgs.length * prices?.['30']?.[currency];
          } else if (uploadedImgs.length >= 12 && uploadedImgs.length <= 19) {
            totals = uploadedImgs.length * prices?.['25']?.[currency];
          } else if (uploadedImgs.length >= 20 && uploadedImgs.length <= 49) {
            totals = uploadedImgs.length * prices?.['22.5']?.[currency];
          } else if (uploadedImgs.length >= 50) {
            totals = uploadedImgs.length * prices?.['20']?.[currency];
          }
          getCredits(restCredits);
          getPrice(totals);
          // console.log("restCredits");
          // console.log(restCredits);
          // console.log("totals");
          // console.log(totals);
        } else {
          restCredits = orderInfos.credit - uploadedImgs.length;
          getCredits(restCredits);
          getPrice(totals);
        }
      }
    }
    //case 3
    if (orderInfos.checked === false && cred.user.Credit !== 0) {
      // console.log("case 3 detected - pack = non , credit = n");
      if (uploadedImgs) {
        if (cred.user.Credit - uploadedImgs.length < 0) {
          restCredits = 0;
          addedImgs = uploadedImgs.length - cred.user.Credit;
          if (addedImgs <= 6) {
            totals = addedImgs * prices?.['35']?.[currency];
          } else if (addedImgs >= 7 && addedImgs <= 11) {
            totals = addedImgs * prices?.['30']?.[currency];
          } else if (addedImgs >= 12 && addedImgs <= 19) {
            totals = addedImgs * prices?.['25']?.[currency];
          } else if (addedImgs >= 20 && addedImgs <= 49) {
            totals = addedImgs * prices?.['22.5']?.[currency];
          } else if (addedImgs >= 50) {
            totals = addedImgs * prices?.['20']?.[currency];
          }
          getCredits(restCredits);
          getPrice(totals);
        } else {
          restCredits = cred.user.Credit - uploadedImgs.length;
          totals = 0;
          getCredits(restCredits);
          getPrice(totals);
        }
      }
    }
    //case 4
    if (orderInfos.checked === true && cred.user.Credit !== 0) {
      // console.log("case 4 detected - pack = oui , credit = n");
      totals = orderInfos.price;
      totalCredits = cred.user.Credit + orderInfos.credit;
      restCredits = totalCredits;
      if (uploadedImgs) {
        if (totalCredits - uploadedImgs.length < 0) {
          restCredits = 0;
          addedImgs = uploadedImgs.length - totalCredits;
          // console.log("addedImgs");
          // console.log(addedImgs);
          if (uploadedImgs.length - cred.user.Credit <= 6) {
            totals += addedImgs * prices?.['35']?.[currency];
          } else if (
            uploadedImgs.length - cred.user.Credit >= 7 &&
            uploadedImgs.length - cred.user.Credit <= 11
          ) {
            totals += addedImgs * prices?.['30']?.[currency];
          } else if (
            uploadedImgs.length - cred.user.Credit >= 12 &&
            uploadedImgs.length - cred.user.Credit <= 19
          ) {
            totals += addedImgs * prices?.['25']?.[currency];
          } else if (
            uploadedImgs.length - cred.user.Credit >= 20 &&
            uploadedImgs.length - cred.user.Credit <= 49
          ) {
            totals += addedImgs * prices?.['22.5']?.[currency];
          } else if (uploadedImgs.length - cred.user.Credit >= 50) {
            totals += addedImgs * prices?.['20']?.[currency];
          }
          getCredits(restCredits);
          getPrice(totals);
        } else {
          restCredits = totalCredits - uploadedImgs.length;
          totals = orderInfos.price;
          getCredits(restCredits);
          getPrice(totals);
        }
      }
    }
  }
  else if(isVirtualRenovation){
if(uploadedImgs){
  totals = uploadedImgs.length * servicesprices?.VR?.price[currency];
  restCredits = cred.user.Credit
  getPrice(totals);
  getCredits(restCredits);
}

// 35
  }
  else if(isThreeDInterior){
    if(uploadedImgs){
      totals = uploadedImgs.length * servicesprices?.THREEDMI?.price[currency];
      restCredits = cred.user.Credit
      getPrice(totals);
      getCredits(restCredits);
    }
  }
  else if(isThreeDExterior){
    if(uploadedImgs){
      totals = uploadedImgs.length * servicesprices?.THREEDME?.price[currency];
      restCredits = cred.user.Credit
      getPrice(totals);
      getCredits(restCredits);
    }
  }
  else if(isItemRemoval){
    if(uploadedImgs){
      totals = uploadedImgs.length * servicesprices?.IR?.price[currency];
      restCredits = cred.user.Credit
      getPrice(totals);
      getCredits(restCredits);
    }
  }
  else if(isImgEnh){
    if(uploadedImgs){
      totals = uploadedImgs.length * servicesprices?.IE?.price[currency];
      restCredits = cred.user.Credit
      getPrice(totals);
      getCredits(restCredits);
    }
  }
  else if(isTwoD){
    if(uploadedImgs){
      totals = uploadedImgs.length * servicesprices?.TWD?.price[currency];
      restCredits = cred.user.Credit
      getPrice(totals);
      getCredits(restCredits);
    }
  }
  else if(isDayToDusk){
    if(uploadedImgs){
      totals = uploadedImgs.length *  servicesprices?.DD?.price[currency];
      restCredits = cred.user.Credit
      getPrice(totals);
      getCredits(restCredits);
    }
  }
  else if(isLuminosity){
    if(uploadedImgs){
      totals = uploadedImgs.length * servicesprices?.PM?.price[currency];
      restCredits = cred.user.Credit
      getPrice(totals);
      getCredits(restCredits);
    }
  }
 



  useEffect(() => {
    getCredits(restCredits);
  }, [restCredits]);

  // console.log("totals")
  // console.log(typeof(totals))



  const formattedTotal = totals.toFixed(2);

  return (
    <div className="credit-element">
      <p className="credit">
       {isVirtualStaging && <>{t("order-form.note-element.credit")} : {restCredits}</>} 
      </p>
      <div className="totals">
        <p className="last-total">Total : {formattedTotal} {getCurrencySymbol(currency)}</p>
        <p className="gray-total">{t('taxes')}</p>
      </div>
    </div>
  );
};

export default Credit;
