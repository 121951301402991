import React from 'react';
import './footer.css';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div>
      <section className="footerMainContent customFooterStyle">
        <div className="allTogether">
          <img src={require('../../assets/img/logo/whiteLogo.png')} alt="MERYAS HOME logo" />
          <p className="email">info@meryashome.com</p>
          <p className="Phonenumber">1 888-649-8993</p>
          <div className="socialMedia">
            <a href="https://www.facebook.com/Meryas.Inc/" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/img/social media logo/whiteFacebook.png')} alt="Facebook icon" />
            </a>
            <a href="https://www.instagram.com/meryas_home/" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/img/social media logo/whiteInstagram.png')} alt="Instagram icon" />
            </a>
            <a href="https://www.youtube.com/channel/UCGQdLW8QYSMQerG3hjxBIWQ" target="_blank" rel="noopener noreferrer">
              <img src={require('../../assets/img/social media logo/whiteYoutube.png')} alt="Youtube icon" />
            </a>
          </div>
          <div className="footerMenu">
            <a href="/">{t('header.menu.m2')}</a>
            <a href="/service/virtual-staging">{t('header.menu.m3')}</a>
            <a href="/services">{t('header.menu.m6')}</a>
            <a href="/gallery">{t('header.menu.m7')}</a>
            <a href="/contact">{t('header.menu.m8')}</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
