import React from "react";
import { useTranslation } from "react-i18next";
import EightFive from "../../../assets/img/weOffer/85ù 1.png";
import Forty from "../../../assets/img/weOffer/40ù 1.png";
import Seventy from "../../../assets/img/weOffer/70ù 1.png";
import "./WeOffer.css";
import WindowWidthComponent from "../../WindowWidthComponent";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const WeOffer = () => {
  const { t } = useTranslation();
  const windowWidth = WindowWidthComponent();
  const options = {
    loop:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navSpeed: 700,
    autoplay: true,
    nav: true,
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      1280: {
        items: 1,
        nav: true,
      },
    },
  };
  const offers = (
    <>
      {" "}
      <div className="detailsConstentOffer">
        <img src={EightFive} alt="85%" />
        <p>{t("home.what-we-offer.bloc1.text")}</p>
      </div>
      <div className="detailsConstentOffer">
        <img src={Forty} alt="40%" />
        <p>{t("home.what-we-offer.bloc2.text")}</p>
      </div>
      <div className="detailsConstentOffer">
        <img src={Seventy} alt="70%" />
        <p>{t("home.what-we-offer.bloc3.text")}</p>
      </div>
    </>
  );
  return (
    <div className="offerContainer">
      <div className="offerMain">
        <h1>{t("home.what-we-offer.title")}</h1>
        <p>{t("home.what-we-offer.text")}</p>

        {windowWidth <= 1280 ? (
          <OwlCarousel className="owl-theme" {...options}>
            {offers}
          </OwlCarousel>
        ) : (
          <div className="contentOffer">{offers}</div>
        )}
      </div>
    </div>
  );
};

export default WeOffer;
