import React from "react";
import { useTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import './Banner.css'
import { LinkContainer } from "react-router-bootstrap";
import bannerData from "../../../data/banner.json"; 
import mobileBannerData from "../../../data/mobile_banner.json"; 
import WindowWidthComponent from "../../WindowWidthComponent";

const Banner = () => {
  const windowWidth = WindowWidthComponent();

  const { t } = useTranslation();
  const customOptions = {
    loop: true,
    autoplay:true,
    autoplayTimeout:3000,
    // autoplayHoverPause:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };

  const images = (imgName) => {
    // console.log(imgName)
    return require(`../../../assets/img/banner/${imgName}`);
  };

  const handleOrderClick = () => {
    localStorage.setItem("serviceURL", "placeOrder");
  };

  return (
    <div className="banner-container">
      <div>
        <OwlCarousel className="owl-theme"  {...customOptions}>
          {windowWidth >800 && bannerData.map(banner => ( 
            <div key={banner.id}>
              <img src={images(banner.image)} alt={banner.alt} />
            </div>
          ))}
          {windowWidth <=800 && mobileBannerData.map(banner => ( 
            <div key={banner.id}>
              <img src={images(banner.image)} alt={banner.alt} />
            </div>
          ))}
        </OwlCarousel>
      </div>
      <div className="banner-header">
        <h1 className="banner-header-title">{t("home.banner.title")}</h1>
        <LinkContainer to={"/placeOrder"} >
          <button className="banner-header-btn" onClick={handleOrderClick}>order  now</button>
        </LinkContainer>
      </div>
    </div>
  );
};

export default Banner;
