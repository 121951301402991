import React from "react";
import Footer from "../../../components/Footer/Footer";
import TestimonialListComponent from "../../../components/TestimonialListComponent";

import { Helmet } from "react-helmet-async";

const Testimonial_List = () => {
  return (
    <div>
      <Helmet>
        <meta name="keywords" content="virtual staging, virtual renovation, 3d modeling interior, 3d modeling exterior, 2d color floor plan, item removal, image enhancement, photo modification, day to dusk, place order, Meryashome, testimonials, customer reviews, feedback" />
        <meta name="description" content="Explore testimonials and customer reviews. Discover what our clients have to say about our products and services." />
      <meta name="robots" content="noindex"/>
      </Helmet>
      <TestimonialListComponent/>
      <Footer />
    </div>
  );
};

export default Testimonial_List;
