import React from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import LoginComponent from '../../../components/Authentification/Login/LoginComponent';
import { Helmet } from 'react-helmet';

const LoginPage = () => {
    return (
        <div>
             <Helmet>
                <meta name="keywords" content="login, authentication, user login, secure login, Meryashome, virtual staging, virtual renovation, 3d modeling interior, 3d modeling exterior, 2d color floor plan, item removal, image enhancement, photo modification, day to dusk" />
                <meta name="description" content="Login to access your account. Secure and user-friendly authentication for a seamless experience." />
                <link rel='canonical' href='https://www.meryashome.com/login' />
            </Helmet>
            <LoginComponent/>
            <Footer/>
        </div>
    );
};

export default LoginPage;