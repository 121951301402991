import React, { useState, useEffect } from "react";
import "./PlaceOrderPage.css";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ServiceCard from "../../components/PlaceOrder/Cards/ServiceCard";
import Cards from "../../data/cards.json";
import { Helmet } from "react-helmet";
import bannerData from "../../data/banner.json";
import OwlCarousel from "react-owl-carousel";
import currencyData from "../../data/currency.json";

const PlaceOrderPage = ({}) => {
  const currency=localStorage.getItem('currency')
  const { t } = useTranslation();
  const customOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 800,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };

  const images = (imgName) => {
    return require(`../../assets/img/banner/${imgName}`);
  };


  const lng = localStorage.getItem("lng");

  const getPrice = (id) => {
    if (id) {
      for (const key in currencyData) {
        if (currencyData[key].id === id) {
          const priceData = currencyData[key].price;
          const price = priceData ? priceData[currency] : undefined;
          if (price && price.includes && price.includes("to") && lng === "fr") {
            const newPrice = price.replace("to", "à");
            return newPrice;
          } else {
            return price;
          }
        }
      }
      return null;
    }
  };
  
  
  
  // const getPrice = (id) => {
  //   if (id) {
  //     for (const key in currencyData) {
  //       if (currencyData[key].id === id) {
  //         return currencyData[key].price[currency];
  //       }
  //     }
  //     return null;
  //   }
  // };

  useEffect(() => {
    // Appel de getPrice avec l'ID approprié pour chaque service
    Cards.map((service, i) => {
      const price = getPrice(service.id);
      // console.log(price);
    });
  }, [currency]);
// console.log("pour le test")
// console.log(currency)


const getCurrencySymbol = (currencyCode) => {
  switch (currencyCode) {
    case 'USD':
      return '$USD';
    case 'EUR':
      return '€';
    case 'AUD':
      return '$AUD';
    case 'CAD':
      return '$CAD';
    case 'GBP':
      return '£';
    case 'NZD':
      return '$NZD';
    default:
      return ''; // Si la devise n'est pas trouvée, retournez une chaîne vide
  }
};


  return (
    <>
      <Helmet>
        <meta name="keywords" content="virtual staging, place order, real estate services, Meryashome, virtual renovation, 3d modeling interior, 3d modeling exterior, 2d color floor plan, item removal, image enhancement, photo modification, day to dusk" />
        <meta name="description" content="Place your order for virtual staging and real estate services. Explore our range of services and enhance your property's visual appeal." />
        <link rel='canonical' href='https://www.meryashome.com/placeOrder' />
      </Helmet>
      {/* <Header /> */}
      <div className="banner-container" style={{ background: 'white', display: 'flex', justifyContent: 'center', marginTop: "50px" }} >
        <div style={{ maxWidth: "800px", width: '100vw' }}>
          <OwlCarousel className="owl-theme" {...customOptions}>
            {bannerData.map(banner => (
              <div key={banner.id}>
                <img src={images(banner.image)} alt={banner.alt} style={{ maxWidth: "800px", width: '100vw' }} />
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
      <div className="placeOrderMainContainer">
        <div className="placeOrderMainContent">
          <div className="placeOrderContentFirstPart">
          </div>
          <div className="placeOrderContentPack">
            <h2>{t("place-order-page.subtitle")}</h2>
            <div className="placeOrderContentPackDetails">
              {Cards.map((service, i) => (
                <ServiceCard
                  key={i + 1}
                  id={i + 1}
                  title={t(`place-order-page.card${i + 1}.title`)}
                  subtitle={t(`place-order-page.card${i + 1}.subtitle`)}
                  price={getPrice(service.id)}
                  currencySymbol={getCurrencySymbol(currency)} // Ajoutez le symbole de la devise comme prop
                  image={service.cardImg.src}
                  altImg={service.cardImg.alt}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PlaceOrderPage;
