import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  First_Name: Yup.string().min(3, 'First name must be at least 3 characters').required('First name is required'),
  Last_Name: Yup.string().min(3, 'Last name must be at least 3 characters').required('Last name is required'),
  phone_number: Yup.string().matches(/^\d{10}$/, 'Phone number must be 10 digits').nullable(),
  Email: Yup.string().matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,'Invalid email address').required('Email is required'),  
  // Password: Yup.string()
  //   .min(8, 'Password must be at least 8 characters')
  //   .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/, 'Invalid password. eg : Abc123@a')
  //   .required('Password is required'),
  Password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .test('uppercase', 'Password must contain at least one uppercase letter', value => /[A-Z]/.test(value))
    .test('lowercase', 'Password must contain at least one lowercase letter', value => /[a-z]/.test(value))
    .test('digit', 'Password must contain at least one digit', value => /\d/.test(value))
    .test('specialChar', 'Password must contain at least one special character (@$!%*?&)', value => /[@$!%*?&]/.test(value))
    .required('Password is required'),
  Confirme_Password: Yup.string().oneOf([Yup.ref('Password'), null], 'Passwords must match').required('Confirmation password is required'),
});

export default validationSchema;
