import React from "react";
import './Contact.css'
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const Contact = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("serviceURL", `contact`);

  }, []);

  const { t } = useTranslation();

  return (
      <div>
         <Helmet>
        <meta name="keywords" content="contact, contact us, form submission, customer support,virtual staging, virtual renovation, 3d modeling interior, 3d modeling exterior, 2d color floor plan, item removal, image enhancement, photo modification, day to dusk, place order, Meryashome" />
        <meta name="description" content="Contact us for any inquiries or support. Fill out the form and we will get back to you. Fast and reliable customer support." />
        <link rel='canonical' href='https://www.meryashome.com/contact'/>


      </Helmet>
          {/* WE NEED ONSUBMIT FUNTIONALITIES */}
      {/* {loading && (
      <div className="loader-container">
        <span className="loader"></span>
      </div>
    )} */}
          {/* <Header /> */}

      <div className="contact-container">
        <h1>{t("contact-us.title")}</h1>
        <p>{t("contact-us.text")}</p>
        <form className="formSubmit">
          <input
            type="text"
            placeholder={t("contact-us.placeholder1")}
            required
            name="name"
          />
          <input
            type="email"
            placeholder={t("contact-us.placeholder2")}
            required
            name="Email"
          />
          <textarea
            cols="30"
            rows="10"
            placeholder={t("contact-us.placeholder3")}
            required
            name="message"
          ></textarea>
          <button>{t("contact-us.btn")}</button>
        </form>
          </div>
          <Footer />
    </div>
  );
};

export default Contact;
