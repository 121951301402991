import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ServiceDetails from "../../../components/HomeComponents/Services/ServiceDetails/ServiceDetails";
import { PlaceOrder } from "../../../components/HomeComponents/PlaceOrderComponent/PlaceOrder";
import Testimonials from "../../../components/Testimonials/Testimonials";
import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/Header/Header";
import Partners from "../../../components/HomeComponents/Partners/Partners";
import { useEffect } from "react";

const ServiceDetailsPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();
  return (
    <>
      {/* <Header /> */}
      <ServiceDetails />
      <div className="go-to-services">
        <p>{t("home.our-services.text")}</p>
        <Link to={"/services"}>
          <button>{t("home.our-services.btn")}</button>
        </Link>
      </div>
      <Partners/>
      <PlaceOrder />
      <Testimonials />
      <Footer />
    </>
  );
};

export default ServiceDetailsPage;
