import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewUser } from "../../../redux/actions/UsersActions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./SignUpComponent.css";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import validationSchema from "./signup-schema";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { environment } from "../../../envirement";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";

const SignUpComponent = () => {
  const captchaRef = useRef(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cred, setCred] = useState({});
  const dispatch = useDispatch();

  const { loading, message, error } = useSelector((state) => state.addNewUser);

  const [recaptchaChecked, setRecaptchaChecked] = useState(false); // State to track reCAPTCHA status
  const [recaptchaError, setRecaptchaError] = useState(false); // State to track reCAPTCHA error

  const formik = useFormik({
    initialValues: {
      First_Name: "",
      Last_Name: "",
      phone_number: "",
      Email: "",
      Password: "",
      Confirme_Password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (!recaptchaChecked) {
        setRecaptchaError(true);
        return;
      }
      // enqueueSnackbar(values)
      setCred({ ...values, Role: "User" });
      dispatch(addNewUser({ ...values, Role: "User" }));
      captchaRef.current.reset();
    },
  });

  const [snackbarDisplayed, setSnackbarDisplayed] = useState(false);

  useEffect(() => {
    if (!loading && !error && Object.keys(cred).length !== 0) {
      if (message && !snackbarDisplayed) {
        enqueueSnackbar(message);
        setSnackbarDisplayed(true);
      }
    }
  }, [loading, error, cred, enqueueSnackbar, message, snackbarDisplayed]);

  useEffect(() => {
    if (snackbarDisplayed) {
      const timeout = setTimeout(() => {
        navigate("/login");
      }, 3000);
        return () => clearTimeout(timeout);
    }
  }, [snackbarDisplayed, navigate]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error);
    }
  }, [error]);

  // Ajouter des états pour gérer la visibilité du mot de passe et de la confirmation du mot de passe
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Définir des gestionnaires d'événements pour basculer la visibilité du mot de passe et de la confirmation du mot de passe
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="signup-container">
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />

      <h3 className="signup-title">{t(`logIn.Signup.title`)}</h3>
      <form onSubmit={formik.handleSubmit}>
        <div className="inputs-container">
          <div className="signup-input-groupe">
            <div className="signup-input">
              <label htmlFor="firstname">{t(`logIn.Signup.label1`)}</label>
              <input
                className="su-input"
                type="text"
                id="firstname"
                placeholder="Doe"
                {...formik.getFieldProps("First_Name")}
              />
              {formik.touched.First_Name && formik.errors.First_Name && (
                <div className="error-message">{formik.errors.First_Name}</div>
              )}
            </div>
            <div className="signup-input">
              <label htmlFor="lastname">{t(`logIn.Signup.label2`)}</label>
              <input
                className="su-input"
                type="text"
                id="lastname"
                placeholder="Miller"
                {...formik.getFieldProps("Last_Name")}
              />
              {formik.touched.Last_Name && formik.errors.Last_Name && (
                <div className="error-message">{formik.errors.Last_Name}</div>
              )}
            </div>
          </div>

          <div className="signup-input-groupe">
            <div className="signup-input">
              <label htmlFor="phone_number">{t(`logIn.Signup.label5`)}{t("champoptionel")}</label>
              <input
                className="su-input"
                type="tel"
                id="phone_number"
                maxLength="10"
                placeholder="01234569878"
                {...formik.getFieldProps("phone_number")}
              />
              {formik.touched.phone_number && formik.errors.phone_number && (
                <div className="error-message">
                  {formik.errors.phone_number}
                </div>
              )}
            </div>

            <div className="signup-input">
              <label htmlFor="Email">{t(`logIn.Signup.label6`)}</label>
              <input
                className="su-input"
                type="text"
                id="Email"
                placeholder="Doe.Miller@example.com"
                {...formik.getFieldProps("Email")}
              />
              {formik.touched.Email && formik.errors.Email && (
                <div className="error-message">{formik.errors.Email}</div>
              )}
            </div>
          </div>

          <div className="signup-input-groupe">
            <div className="signup-input">
              <label htmlFor="Password">{t(`logIn.Signup.label4`)}</label>
              <Input
            className="su-input"
                type={showPassword ? "text" : "password"}
                id="Password"
                {...formik.getFieldProps("Password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.Password && formik.errors.Password && (
                <div className="error-message">{formik.errors.Password}</div>
              )}
            </div>

            <div className="signup-input">
              <label htmlFor="Confirme_Password">
                {t(`logIn.Signup.label8`)}
              </label>
              <Input
            className="su-input"
            type={showConfirmPassword ? "text" : "password"}
                id="Confirme_Password"
                {...formik.getFieldProps("Confirme_Password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.Confirme_Password &&
                formik.errors.Confirme_Password && (
                  <div className="error-message">
                    {formik.errors.Confirme_Password}
                  </div>
                )}
            </div>
          </div>
        </div>

        <ReCAPTCHA
          sitekey={environment.captchaKey}
          ref={captchaRef}
          onChange={() => setRecaptchaChecked(true)}
          className={recaptchaError ? "recaptcha-error" : ""}
        />

        <button type="submit" className="submit-btn">
          {t(`logIn.logIn.btn`)}
        </button>
      </form>
    </div>
  );
};

export default SignUpComponent;
